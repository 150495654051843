<template>
  <admin>
    <metatag title="Sales Consultants"></metatag>

    <page-header
      :back="{ to: { name: 'dealers-account-maintenance.dealers.index' }, text: trans('Dealers') }"
    >
      <template v-slot:action>
        <v-menu bottom offset-y class="pointer">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :block="$vuetify.breakpoint.smAndDown"
              large
              color="primary"
              exact
              v-bind="attrs"
              v-on="on"
              class="mx-5 mx-md-2 mt-2 mt-md-0 white--text"
              :to="{ name: 'dealers-account-maintenance.dealerusers.create' }"
            >
              <v-icon small left>mdi-account-plus-outline</v-icon>
              Add new
            </v-btn>
          </template>
        </v-menu>
      </template>
    </page-header>
    <v-card>
      <toolbar-menu
        ref="toolbar"
        :items.sync="tabletoolbar"
        @update:search="setSearch"
      ></toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table
          :options.sync="options"
          :headers="dealer_users.headers"
          :items="dealer_users.data"
          color="primary"
          item-key="id"
          class="text-no-wrap"
          :loading="loading"
          :items-per-page="10"
        >
          <template v-slot:item.id="{ item }">
              <v-btn 
                :to="{
                      name: 'dealers-account-maintenance.dealerusers.edit',
                      params: { id: item.id },
                    }"
                text 
                color="primary" 
                icon>
                {{ item.id }}
              </v-btn>
          </template>
          <template v-slot:item.role="{ item }">
            <v-chip
              v-for="role in item.role"
              :key="role.id"
              class="ma-2"
              label
            >
              {{ role.name }}
            </v-chip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip v-if="item.status == true" class="ma-2" color="green" text-color="white">
              active
            </v-chip>
            <v-chip v-else class="ma-2" color="red" text-color="white"> inactive </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-no-wrap">
              <v-tooltip bottom v-if="!item.is_email_verified">
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    @click="resendEmailVerification(item)"
                  >
                    <v-icon small>mdi-email-alert</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Resend email verification'"></span>
              </v-tooltip>
              <!-- Edit -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    :to="{
                      name: 'dealers-account-maintenance.dealerusers.edit',
                      params: { id: item.id },
                    }"
                    icon
                    v-on="on"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Edit'"></span>
              </v-tooltip>
              <!-- Edit -->
              <!-- Delete -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text icon v-on="on" @click="confirmDelete(item.id)">
                    <v-icon small>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Delete'"></span>
              </v-tooltip>
              <!-- Delete -->
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";

export default {
  data() {
    return {
      loading: true,
      tabletoolbar: {
        isSearching: false,
        search: null,
      },
      options: {},
      total: 0,
      filters: [
        { text: "Name", value: "name" },
        { text: "Status", value: "status" },
        { text: "Date Updated", value: "year" },
      ],
      dealer_users: {
        per_page: 10,
        headers: [
          { text: "Id", align: "left", value: "id", class: "text-no-wrap" },
          { text: "First Name", align: "left", value: "first_name", class: "text-no-wrap" },
          { text: "Last Name", align: "left", value: "last_name", class: "text-no-wrap" },
          { text: "Email", value: "email", class: "text-no-wrap" },
          { text: "CRM ID", value: "crm_id", class: "text-no-wrap" },
          {
            text: "Roles",
            align: "start",
            sortable: false,
            value: "role",
          },
          {
            text: "Status",
            align: "start",
            sortable: true,
            value: "status",
          },
          {
            text: "Actions",
            value: "actions",
            class: "muted--text text-no-wrap",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(this.dealer_users.per_page);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "dealerUser/GET_DEALER_USERS",
    }),
  },
  methods: {
    ...mapActions({
      getDealerUsers: "dealerUser/getDealerUsers",
      deleteDealerUser: "dealerUser/delete",
      resendEmalVerificationDealerUser: "dealerUser/resendEmalVerificationDealerUser",
      showDialog: "dialog/show",
      hideDialog: "dialog/hide",
    }),

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.getItems(1, itemsPerPage, e.target.value);
    }, 300),

    getItems(page, itemsPerPage, q = "") {
      const dealer_id = this.$route.params.dealer_id ? this.$route.params.dealer_id : null;
      let self = this;

      this.getDealerUsers({
        page: page,
        per_page: itemsPerPage,
        q: q,
        dealer_id: dealer_id,
      }).then((data) => {
        self.dealer_users.data = self.data.data;
        self.loading = false;
        self.$refs.toolbar.items.isSearching = false;
      });
    },

    confirmDelete(id) {
      this.showDialog({
        title: trans("Delete User"),
        text: trans("Are you sure you want to delete this user?"),
        buttons: {
          cancel: {
            text: trans("Cancel"),
            callback: () => {
              this.hideDialog();
            },
          },
          action: {
            color: "error",
            text: trans("Delete"),
            callback: async () => {
              const { page, itemsPerPage } = this.options;

              await this.deleteDealerUser(id);
              await this.getItems(1, itemsPerPage);
              
              this.hideDialog();
            },
          },
        },
      });
    },

    async resendEmailVerification(item) {
      await this.resendEmalVerificationDealerUser({ email: item.email })
    }
  },
};
</script>

<style></style>
